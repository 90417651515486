<template>
  <div class="container">
    <house-header :tradeType="tradeType" v-if="!isSourceERP" />
    <div class="contaienr-header">
      <div class="content flex sb_center">
        <div>
          <h2 class="size26 bold">
            {{ houseInfo.title || houseInfo.name }}
          </h2>
          <span class="size12 gray">此房源位于{{ houseInfo.address}}</span>
        </div>
       <div v-if="isSourceERP" class="kanfangShare" @click="showKanfangShare=true">
            分享房源
        </div>
      </div>

    </div>

    <div class="content">
      <div class="row house-imgs">
        <div class="">
          <RentCarousel
            :imgs="imgs"
            :vrUrl="houseInfo.vrUrl"
            :videoUrl="houseInfo.videoUrl"
            :coverImage="houseInfo.coverImage"
            :videoImageUrl="houseInfo.videoImageUrl"
            :size="'min'"
          />
        </div>
        <div class="house-imgs-right flex1 ml30 ph40">
          <div v-if="tradeType == 1">
            <div class="size12  row">
              <div class="red flex1">
                <span style="font-size: 40px">{{
                  houseInfo.rentPrice || "--"
                }}</span>
                <span>元/月</span>
                <span>(年付价)</span>
              </div>

              <div v-show="!isSourceERP"
                @click="isShowQRCode = true"
                class="flex s_center cursor"
                style="margin-right: 26px"
              >
                <img
                  class="img14 mr5"
                  src="../../assets/icon/erp_icon_wechat.png"
                  alt=""
                />
                <span> 分享</span>
              </div>
              <div v-show="!isSourceERP"
                @click="changeCollection"
                class="flex s_center cursor"
              >
                <i
                  class="el-icon-star-off size16 black"
                  v-if="!isEstateAttention"
                ></i>
                <i
                  class="el-icon-star-on size20 black"
                  v-if="isEstateAttention"
                ></i>
                <span> {{ isEstateAttention ? "取消关注" : "关注" }}</span>
              </div>
            </div>
            <ul
              v-if="houseInfo.tag && houseInfo.tag.length"
              class="pt10 flex wrap border-b"
            >
              <li
                class="tag-item mr10 mb10 size14"
                v-for="tag in houseInfo.tag"
                :class="{ activeTag: tag === '必看好房' }"
                :key="tag"
              >
                {{ tag }}
              </li>
            </ul>
            <div class="mt10 size12 border-b">
              <div class="mb10">
                <span class="whiteGray">房屋地址</span>
                <span class="ml20">{{ houseInfo.estateName }}</span>
              </div>
              <div
                v-if="houseInfo.room"
                class="mb10 row"
              >
                <span class="whiteGray">房屋类型</span>
                <label class="ml20 row">
                  <span v-if="houseInfo.room">{{ houseInfo.room + "室" }}</span>
                  <span v-if="houseInfo.hall">{{ houseInfo.hall + "厅" }}</span>
                  <span
                    v-if="houseInfo.buildingArea"
                    style="margin: 0 10px"
                  >{{
                    houseInfo.buildingArea + "㎡"
                  }}</span>
                  <span v-if="houseInfo.decorate">{{
                    houseInfo.decorate
                  }}</span>
                </label>
              </div>
              <div class="mb10 row">
                <span class="whiteGray">朝向楼层</span>
                <label class="ml20 row">
                  <span v-if="houseInfo.orientation!='未选择'">{{
                    houseInfo.orientation
                  }}</span>
                  <span v-if="houseInfo.currentFloor">{{
                    houseInfo.currentFloor
                  }}</span>
                  <span v-if="houseInfo.totalFloor">{{"/"+ houseInfo.totalFloor }}</span>
                </label>
              </div>
              <div class="mb10">
                <span class="whiteGray">风险提示</span>
                <a
                  class="ml20 green"
                  @click="showRiskTip = true"
                  href="javascript:;"
                >用户风险提示</a>
              </div>
              <div class="mb10 row">
                <span class="whiteGray">验真房源</span>
                <label class="ml20 row">
                  <span>{{ houseInfo.houseNo || "" }}</span>
                </label>
              </div>
            </div>
          </div>

          <div class="mt10">
            <agent-data
              :tradeType="1"
              :agent-data="agentData"
              @show-code="toMimiWeChat"
            />
          </div>
        </div>
      </div>
      <div class="house-info">
        <ul
          ref="houseTabRef"
          class="row grayBG s_center"
          style="height: 64px"
        >
          <div class="row flex1">
            <li
              style="line-height: 64px; text-align: center"
              class="size20 relative ph20 cursor"
              v-for="tab in houseTabs"
              :key="tab.key"
              v-show="tab.key!=3||estateInfo.lng"
            >
              <!-- :href="tab.id" -->
              <a
                class="whiteGray"
                :class="{ col4d: tab.choose }"
                @click="onClickTabItem(tab)"
              >
                {{ tab.title }}
              </a>
              <div
                v-show="tab.choose"
                class="absolute tab-bottom greenBG"
              ></div>
            </li>
          </div>

          <el-button
            v-if="agentData.fixTel || agentData.mobile"
            style="box-sizing: border-box; height: 44px"
            class="tButton phoneButton greenBG size16"
          >
            <i class="el-icon-phone"></i>
           {{isSourceERP?agentData.mobile:agentData.fixTel || agentData.mobile}}
          </el-button>
          <el-button v-show="!isSourceERP"
            style="width: 120px; box-sizing: border-box; height: 44px"
            @click="isShowQRCode = true"
            plain
            class="tButton chatButton greenBG green size16 mr25"
          >
            <i class="el-icon-time"></i>
            预约看房
          </el-button>
        </ul>
        <div class="row">
          <div
            class="flex"
            style="padding-top: 0px"
          >
            <div style="width: 870px">
              <div>
                <p
                  class="row s_center pv20 fontS16 border-b cursor"
                  style="color: #394043"
                >
                  <img
                    class="mr20 ml20"
                    style="width: 22px; height: 30px"
                    src="../../assets/icon/house_icon_realHouse.png"
                    alt=""
                  />
                  <span
                    style=""
                    class="flex1 row s_center"
                  >真实存在，真实在租，真实价格，真实图片，假一赔百元
                    <router-link
                      class="row e_center"
                      target="_blank"
                      :to="{
                      path: '/servicePromise',
                    }"
                    >
                      <img
                        class="ml10"
                        style="width:20px;height:20px;"
                        src="../../assets/icon/app_icon_warn.png"
                        alt=""
                      >
                    </router-link>
                  </span>
                  <span v-show="!isSourceERP"
                    @click="isShowQRCode = true"
                    class="green"
                  >我要举报</span>
                </p>
              </div>
              <div id="house-info">
                <h1
                  style="padding: 20px 0 30px"
                  class="border-b size24 bold"
                >
                  房源基本信息
                </h1>
                <div
                  v-if="tradeType == 1"
                  class="row size18 whiteGray border-b"
                  style="padding: 28px 0"
                >
                  <div style="width: 142px">
                    <span>基本属性</span>
                  </div>
                  <div class="flex1 row">
                    <div class="flex1">
                      <div class="mb15 black">
                        <span>面积：</span>
                        <span class=" black">{{
                          houseInfo.buildingArea?houseInfo.buildingArea
                        +'㎡':''}}</span>
                      </div>

                      <div class="mb15 black">
                        <span>楼层：</span>
                        <span class=" black">{{
                          houseInfo.currentFloor?houseInfo.currentFloor + "/":''
                        }}</span>
                        <span>{{ houseInfo.totalFloor?houseInfo.totalFloor+'层':'' }}</span>
                      </div>
                      <div class="mb15 black">
                        <span>车位：</span>
                        <span class=" black">暂无数据</span>
                      </div>
                      <div class="mb15 black">
                        <span>用电：</span>
                        <span class=" black">暂无数据</span>
                      </div>
                      <div class="mb15 black">
                        <span>燃气：</span>
                        <span class=" black">暂无数据</span>
                      </div>
                      <div class="mb15 black">
                        <span>租期：</span>
                        <span class=" black">暂无数据</span>
                      </div>
                      <div class="mb15 black">
                        <span>看房：</span>
                        <span class=" black">需提前预约</span>
                      </div>
                    </div>
                    <div class="flex1">
                      <div class="mb15">
                        <span class="inline-b black">朝向：</span>
                        <span class="black">{{ houseInfo.orientation }}</span>
                      </div>
                      <div class="mb15">
                        <span class="inline-b black">入住：</span>
                        <span class="black">随时入住</span>
                      </div>
                      <div class="mb15">
                        <span class="inline-b black">现状：</span>
                        <span class="black">暂无数据</span>
                      </div>
                      <div class="mb15">
                        <span class="inline-b black">物业费：</span>
                        <span class="black">暂无数据</span>
                      </div>
                      <div class="mb15">
                        <span class="inline-b black">用水：</span>
                        <span class="black">暂无数据</span>
                      </div>
                      <!-- <div style="height: 40px"></div> -->
                      <div class="mb15">
                        <!-- <a href=""
                                                class="blue ">咨询租期</a> -->
                        <span
                          @click="isShowQRCode = true"
                          class="green cursor"
                        >咨询租期</span>
                      </div>
                      <div class="mb15">
                        <!-- <a href=""
                                                class="blue ">咨询看房</a> -->
                        <span
                          @click="isShowQRCode = true"
                          class="green cursor"
                        >咨询看房</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="house-feature">
                <h1
                  style="padding: 20px 0 30px"
                  class="border-b size24 bold"
                >
                  房源描述
                </h1>
                <div class="mt10">
                  <div class="row">
                    <div class="avatar mr10">
                      <img
                        v-if="agentData.avatar"
                        :src="agentData.avatar"
                        class="all fillet"
                        alt=""
                      />
                      <img
                        v-else
                        :src="defaultAvatar"
                        class="all fillet"
                        alt=""
                      />
                    </div>
                    <div class="pl5 pt5 flex1">
                      <div class="flex s_center">
                        <h2 class="size20 bold">{{ agentData.trueName||'经纪人' }}</h2>
                        <div class="departmentName ml20 size12">
                          {{ agentData.departmentName }}
                        </div>
                      </div>
                      <p
                        class="size12 pt5"
                        style="color: #cccccc"
                      >
                        好评经纪人
                      </p>
                    </div>
                    <el-button
                      style="
                        box-sizing: border-box;
                        height: 40px;
                        padding: none;
                      "
                      v-if="agentData.fixTel || agentData.mobile"
                      class="tButton phoneButton greenBG size16"
                    >
                      <i class="el-icon-phone"></i>
                       {{isSourceERP?agentData.mobile: agentData.fixTel || agentData.mobile}}
                    </el-button>
                  </div>
                </div>

                <div class="feature size18 border-b">
                  <div
                    class="mb5"
                    style="width: 220px"
                  >
                    <span class="">【核心卖点】</span>
                  </div>
                  <span>此房户型方正，装修保养好，家具家电全新，采光充足。</span>
                </div>
                <div
                  v-show="seeMore"
                  class="feature size18 border-b"
                >
                  <div
                    class="mb5"
                    style="width: 220px"
                  >
                    <span class="">【小区介绍】</span>
                  </div>
                  <span class="flex1">{{ tabs[0].value || "暂无介绍" }}</span>
                </div>
                <div
                  v-show="seeMore"
                  class="feature size18 border-b"
                >
                  <div
                    class="mb5"
                    style="width: 220px"
                  >
                    <span class="">【周边配套】</span>
                  </div>
                  <span class="flex1">{{ tabs[1].value || "暂无介绍" }}</span>
                </div>
                <div
                  v-show="seeMore"
                  class="feature size18"
                >
                  <div
                    class="mb5"
                    style="width: 220px"
                  >
                    <span class="">【交通出行】</span>
                  </div>
                  <span class="flex1">{{ tabs[2].value || "暂无介绍" }} </span>
                </div>
                <span
                  v-show="seeMore"
                  class="size14 whiteGray"
                >注：1.房源介绍中的周边配套、在建设施、规划设施、地铁信息、绿化率、得房率、容积率等信息为通过物业介绍、
                  房产证、实勘、政府官网等渠道获取，因时间、政策会发生变化，与实际情况可能略有偏差，房源介绍仅供参考。
                  2.房源介绍中与距离相关的数据均来源于百度地图。3.土地使用起止年限详见业主土地证明材料或查询相关政府部门
                  的登记文件。</span>
                <div
                  v-show="!seeMore"
                  @click="seeMore = true"
                  class="size14 whiteGray row c_center pv10"
                >
                  查看更多 <i class="el-icon-arrow-down ml10"></i>
                </div>

                <ul class="piclist mt20">
                  <li
                    v-for="(item, index) in imgs"
                    :key="index"
                    @click="showBigImgF(item)"
                  >
                    <img
                      :src="item.serverPath"
                      alt=""
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="flex1 row">
            <div class="flex1"></div>
            <div style="width: 170px">
              <h1
                style="padding: 40px 0px 30px 0px"
                class="size20 bold"
              >
                必看好房
              </h1>
              <ul class="mustLookList">
                <div
                  v-for="(item, index) in recommendHouse"
                  v-show="index < 5"
                  :key="item.guid"
                  class="mustLook-house-item-div"
                >
                  <router-link
                    class="mustLook-house-item"
                    target="_blank"
                    :to="{
                      name: 'rent-house-detail',
                      query: {
                        guid: item.guid,
                        tradeType: tradeType,
                      },
                    }"
                  >
                    <div class="">
                      <img
                        v-if="item.coverImage"
                        class="all"
                        :src="item.coverImage"
                        alt=""
                      />
                      <img
                        v-else
                        class="all"
                        :src="defaultCoverImg"
                        alt=""
                      />
                      <span
                        class="tag-item size14"
                        v-for="tag in item.tag"
                        v-show="tag == '必看好房'"
                        :class="{ activeTag: tag == '必看好房' }"
                        :key="tag"
                      >
                        {{ tag }}
                      </span>
                    </div>

                    <p class="title">{{ item.title }}</p>
                    <p class="whiteGray mb8">
                      <span v-if="item.region">{{ item.region + "/" }}</span>
                      <span v-if="item.orientation!='未选择'">{{
                        item.orientation + "/"
                      }}</span>
                      <span v-if="item.buildingArea">{{
                        item.buildingArea + "㎡"
                      }}</span>
                    </p>
                    <p class="mb8">
                      <span class="red size16">{{
                        item.rentPrice + "元/月"
                      }}</span>
                    </p>
                  </router-link>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        ref="mapHouseRef"
        id="house-near"
        class="mt40"
        style="height: 650px"
        v-show="estateInfo.lng"
      >
        <map-peripheral
          v-if="estateInfo.lng && estateInfo.lat"
          :lng="estateInfo.lng"
          :lat="estateInfo.lat"
          :estateName="estateInfo.name"
        />
      </div>

      <div id="house-neighborhood">
        <h1 class="size24 col4d mb20 bold mt40">附近房源</h1>
        <ul
          v-if="nearHouse.length"
          class="near-house flex wrap"
        >
          <router-link
            class="near-house-item"
            target="_blank"
            v-for="item in nearHouse"
            :key="item.guid"
            :to="{
              name: 'rent-house-detail',
              query: {
                guid: item.guid,
                tradeType: tradeType,
              },
            }"
          >
            <div
              class="mb10"
              style="width: 100%; height: 220px"
            >
              <img
                v-if="item.coverImage"
                class="all"
                :src="item.coverImage"
                alt=""
              />
              <img
                v-else
                class="all"
                :src="defaultCoverImg"
                alt=""
              />
              <span
                class="tag-item size14"
                v-for="tag in item.tag"
                v-show="tag == '必看好房'"
                :class="{ activeTag: tag == '必看好房' }"
                :key="tag"
              >
                {{ tag }}
              </span>
            </div>
            <div v-if="tradeType == 1">
              <div class="flex sb_center size16 bold">
                <p
                  style="width: 160px"
                  class="size16 black ellipsis"
                >
                  {{ item.title }}
                </p>
                <span class="red">{{ item.rentPrice + "元/月" }}</span>
              </div>
              <p class="size14 whiteGray">
                <span v-if="item.region">{{ item.region + "/" }}</span>
                <span v-if="item.orientation">{{
                  item.orientation + "/"
                }}</span>
                <span v-if="item.buildingArea">{{
                  item.buildingArea + "㎡"
                }}</span>
              </p>
            </div>
            <div v-else>
              <p class="size16 black">{{ item.region }}</p>
              <p class="size16 black">{{ item.title }}</p>
              <div class="flex sb_center mt10">
                <div class="size14 whiteGray">
                  <span v-if="item.room">{{ item.room + "室" }}</span>
                  <span v-if="item.hall">{{ item.hall + "厅." }}</span>
                  <span v-if="item.buildingArea">{{
                    item.buildingArea + "平米"
                  }}</span>
                </div>
                <span class="size16 bold red">{{ item.price + "万" }}</span>
              </div>
            </div>
          </router-link>
        </ul>
        <p
          v-else
          class="size18 whiteGray pv20 border-b center"
        >附近暂无房源</p>
      </div>
      <div
        id="house-recommend"
        class=""
      >
        <h1 class="size24 col4d mb20 bold">为您推荐优质房源</h1>
        <ul
          v-if="recommendHouse.length"
          class="near-house flex wrap"
        >
          <router-link
            class="near-house-item"
            target="_blank"
            v-for="item in recommendHouse"
            :key="item.guid"
            :to="{
              name: 'rent-house-detail',
              query: {
                guid: item.guid,
                tradeType: tradeType,
              },
            }"
          >
            <div
              class="mb10"
              style="width: 100%; height: 220px"
            >
              <img
                v-if="item.coverImage"
                class="all"
                :src="item.coverImage"
                alt=""
              />
              <img
                v-else
                class="all"
                :src="defaultCoverImg"
                alt=""
              />
              <span
                class="tag-item size14"
                v-for="tag in item.tag"
                v-show="tag == '必看好房'"
                :class="{ activeTag: tag == '必看好房' }"
                :key="tag"
              >
                {{ tag }}
              </span>
            </div>
            <div>
              <div class="flex sb_center size16 bold">
                <p
                  style="width: 160px"
                  class="size16 black ellipsis"
                >
                  {{ item.title }}
                </p>
                <span class="red">{{ item.rentPrice + "元/月" }}</span>
              </div>
              <p class="size14 whiteGray">
                <span v-if="item.region">{{ item.region + "/" }}</span>
                <span v-if="item.orientation">{{
                  item.orientation + "/"
                }}</span>
                <span v-if="item.buildingArea">{{
                  item.buildingArea + "㎡"
                }}</span>
              </p>
            </div>
          </router-link>
        </ul>
        <p
          v-else
          class="size18 whiteGray pv20 border-b center"
        >附近暂无房源</p>
        <div
          @click="toMoreRecommend"
          v-if="recommendHouse.length"
          class="flex mv20"
        >
          <el-button class="flex1 size18 col4d">更多推荐房源</el-button>
        </div>
      </div>
      <div v-show="!isSourceERP">
        <h1 class="size24 col4d mb20 bold">
          我有房子要租
        </h1>
        <div class="row">
          <div class="yezhu-item yezhu-item-gujia flex1 column c_center">
            <!-- <img src="../../assets/imgs/yezhu-gujia.png" alt="" class="yezhu-item-img"> -->
            <div class="yezhu-item-marsk"></div>
            <div class="yezhu-item-msg-tit">不知道如何定价</div>
            <div class="yezhu-item-msg-sub-tit">到成交频道查看成交明细</div>
            <router-link
              :to="{
                path: '/house/1',
              }"
              target="_blank"
              style="color: #fff; z-index: 4"
            >查成交﹥</router-link>
          </div>
          <div class="yezhu-item yezhu-item-maifang flex1 column c_center">
            <!-- <img src="../../assets/imgs/yezhu-gujia.png" alt="" class="yezhu-item-img"> -->
            <div class="yezhu-item-marsk"></div>
            <div class="yezhu-item-msg-tit">把房源委托给{{companyConfig.firstName}}</div>
            <div class="yezhu-item-msg-sub-tit">
              专业经纪人·线上千万级浏览量
            </div>
            <router-link
              :to="{
                path: '/owner/' + tradeType,
              }"
              target="_blank"
              style="color: #fff; z-index: 4"
            >发布房源﹥</router-link>
          </div>
          <div class="yezhu-item yezhu-item-xiazai flex1 column c_center">
            <!-- <img src="../../assets/imgs/yezhu-gujia.png" alt="" class="yezhu-item-img"> -->
            <div class="yezhu-item-marsk"></div>
            <div class="yezhu-item-msg-tit">已有房源在{{companyConfig.firstName}}出租</div>
            <div class="yezhu-item-msg-sub-tit">
              去{{companyConfig.firstName+companyConfig.lastName}}APP业主中心管理委托
            </div>
            <div
              class=" hoverDiv"
              style=" z-index: 4"
            >
              <span
                class="hoverDiv-cu"
                style="color: #fff; z-index: 4"
              >去APP管理委托﹥</span>
              <span class="layer-qrcode">
                <span class="icon-qrcode">
                  <img
                    :src="companyConfig.downloadUrl"
                    alt=""
                  >
                </span>
                <span class="txt">去{{companyConfig.firstName + companyConfig.lastName}}APP提升卖房速度</span>
                <span class="sub-txt">APP·移动卖房新体验</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <risk-tip
      :show-risk-tip="showRiskTip"
      @update-show-risk-tip="showRiskTip = $event"
    />

    <div
      v-if="needFxied"
      class="needFxied grayBG"
    >
      <ul
        class="row grayBG content relative s_center"
        style="height: 64px"
      >
        <div class="row flex1">
          <li
            style="line-height: 64px; text-align: center"
            class="size20 relative ph20 cursor"
            v-for="tab in houseTabs"
            :key="tab.key"
            v-show="tab.key!=3||estateInfo.lng"
          >
            <!-- :href="tab.id" -->
            <a
              class="whiteGray"
              :class="{ col4d: tab.choose }"
              @click="onClickTabItem(tab)"
            >
              {{ tab.title }}
            </a>
            <div
              v-show="tab.choose"
              class="absolute tab-bottom greenBG"
            ></div>
          </li>
        </div>

        <el-button
          style="box-sizing: border-box; height: 44px"
          v-if="agentData.fixTel || agentData.mobile"
          class="tButton phoneButton greenBG size16"
        >
          <i class="el-icon-phone"></i>
          {{isSourceERP?agentData.mobile:agentData.fixTel || agentData.mobile}}
        </el-button>
        <el-button v-show="!isSourceERP"
          style="width: 120px; box-sizing: border-box; height: 44px"
          @click="isShowQRCode = true"
          plain
          class="tButton chatButton greenBG green size16 mr25"
        >
          <i class="el-icon-time"></i>
          预约看房
        </el-button>
      </ul>
    </div>

    <qr-code-dialog
      :show-code="isShowQRCode"
      :page="13"
      :houseGuid="guid"
      :tradeType="tradeType"
      @update-show-code="isShowQRCode = $event"
    />
    <BigImgCarousel
      :isShowBigImg="isShowBigImg"
      :houseImage="imgs"
      :newImage="clickImg"
      @close="close"
    />
     <kf-qr-code-dialog
      :show-code="showKanfangShare"
      :page="1"
      :houseNo="houseInfo.houseNo"
      @update-show-code="showKanfangShare = $event"
    />
  </div>
</template>

<script>
import HouseHeader from "../../components/house-header";
import Constant from "../../common/Constant.js";
// import moment from 'moment'
import RentCarousel from "../../components/RentCarousel.vue";
import AgentData from "../../components/AgentData.vue";
import RiskTip from "../../components/RiskTip.vue";
import tool from "../../utils/tool.js";
// import HousePriceComputed from '../../components/HousePriceComputed'
import MapPeripheral from "../../components/MapPeripheral.vue";
import realHouseServer from "../../services/realHouseServer.js";
import houseServer from "../../services/houseServer.js";
import Dialog from "../../components/Dialog";
import userServer from "../../services/userServer.js";
import BigImgCarousel from "../../components/BigImgCarousel.vue";
import { mapState } from "vuex";
import KfQrCodeDialog from "../../components/KanfangShareCode.vue";
export default {
  name: "houseDetail",
  props: {},
  data() {
    return {
      guid: "",
      type: 1,
      statisType: 3,
      tradeType: 2,
      houseInfo: {},
      imgs: [], //[1,2,3,4,5,6,7,8]

      takeFeedback: [],
      agentData: {},

      showRiskTip: false,

      deleteTags: [],
      defaultCoverImg: Constant.DEFAULT_HOUSE,
      defaultAvatar: Constant.DEFAULT_AVATAR,
      iframeUrl: Constant.TAXATION_URL,
      dynamic: {},
      lng: 104.071,
      lat: 30.56,
      tabs: [
        {
          name: "小区介绍",
          value: "",
        },
        {
          name: "周边配套",
          value: "",
        },
        {
          name: "交通出行",
          value: "",
        },
      ],
      estateInfo: {
        lng: "",
        lat: "",
        name: "",
      },
      houseTabs: [],
      matching: [
        {
          title: "洗衣机",
          icon: require("../../assets/icon/web_icon_machine.png"),
          isHave: true,
        },
        {
          title: "空调",
          icon: require("../../assets/icon/web_icon_conditioner.png"),
          isHave: false,
        },
        {
          title: "衣柜",
          icon: require("../../assets/icon/web_icon_wardrobe.png"),
          isHave: true,
        },
        {
          title: "电视",
          icon: require("../../assets/icon/web_icon_tv.png"),
          isHave: true,
        },
        {
          title: "冰箱",
          icon: require("../../assets/icon/web_icon_refrigerator.png"),
          isHave: true,
        },
        {
          title: "热水器",
          icon: require("../../assets/icon/web_icon_heater.png"),
          isHave: true,
        },
        {
          title: "床",
          icon: require("../../assets/icon/web_icon_bed.png"),
          isHave: true,
        },
        {
          title: "暖气",
          icon: require("../../assets/icon/web_icon_heating.png"),
          isHave: true,
        },
        {
          title: "WiFi",
          icon: require("../../assets/icon/web_icon_wifi.png"),
          isHave: true,
        },
        {
          title: "天然气",
          icon: require("../../assets/icon/web_icon_natural.png"),
          isHave: true,
        },
      ],
      otherAgents: [],
      nearHouse: [],
      recommendHouse: [],
      dealHistories: [],
      searchParamsTake: {
        page: 1,
        itemsPerPage: 6,
        companyGuid: Constant.DEFULT_COMPANY_GUID,
      },
      searchParams: {
        page: 1,
        itemsPerPage: 6,
        type: 2,
      },
      takeRecords: [],
      needFxied: false,
      needRightFxied: false,

      isDisabled: false,
      isShowQRCode: false,

      seeMore: false,
      isEstateAttention: false,

      isShowBigImg: false,
      clickImg: {},
      // BigImgs: [],
      showKanfangShare:false
    };
  },
  components: {
    HouseHeader,
    RentCarousel,
    AgentData,
    RiskTip,
    // HousePriceComputed,
    MapPeripheral,
    QrCodeDialog: Dialog,
    BigImgCarousel,
    KfQrCodeDialog: KfQrCodeDialog,
  },
  watch: {
    $route(newVal, val) {
      if (newVal.params.tradeType !== val.params.tradeType) {
        this.tradeType = +newVal.params.tradeType;
      }
    },
    nearHouse(newVal) {
      if (newVal.length > 0) {
        let houseTabs = this.deepCopy(this.houseTabs);
        houseTabs.forEach((item) => {
          let scrollTop = document.querySelector(item.id).offsetTop;
          item.maxTop = scrollTop;
        });
        this.houseTabs = houseTabs;
        window.onscroll = tool.throttle(this.onScroll, 200);
      }
    },
  },
  computed: {
    ...mapState(["user","companyConfig","isSourceERP"]),
  },
  methods: {
    showBigImgF(img) {
      this.clickImg = img;
      this.isShowBigImg = true;
    },
    close(isShow) {
      // console.log("close>>", isShow);
      this.isShowBigImg = false;
    },
    goVr() {
      window.open(this.houseInfo.vrUrl);
    },
    getTradeType() {
      const {
        params: { tradeType },
      } = this.$route;
      this.tradeType = +tradeType;
      this.onLoad();
    },
    toMimiWeChat() {
      this.isShowQRCode = true;
    },
    onLoad() {
      const {
        guid = "aefd421f529447dea12570afbf277b8c",
        type,
        statisType,
      } = this.$route.query;
      this.guid = guid;
      this.type = type;
      this.statisType = statisType;
      if (this.tradeType == 2) {
        this.houseTabs = [];
      } else {
        this.houseTabs = [
          {
            title: "房源基本信息",
            choose: true,
            key: 1,
            id: "#house-info",
          },
          {
            title: "房源描述",
            choose: false,
            key: 2,
            id: "#house-feature",
          },
          {
            title: "周边配套",
            choose: false,
            key: 3,
            id: "#house-near",
          },

          {
            title: "附近房源",
            choose: false,
            key: 5,
            id: "#house-neighborhood",
          },
          {
            title: "推荐房源",
            choose: false,
            key: 6,
            id: "#house-recommend",
          },
        ];
      }
      this.getHouseInfo();
      this.loadAgentRecommend();
      this.loadHouseImags();
      if (this.user) {
        this.loadHouseCollection(guid);
      }
    },
    onClickTabItem(item) {
      if (item.choose) return;
      document.querySelector(item.id).scrollIntoView();
      let houseTabs = this.deepCopy(this.houseTabs);
      houseTabs.forEach((ele) => {
        if (item.key === ele.key) {
          ele.choose = true;
        } else {
          ele.choose = false;
        }
      });
      this.houseTabs = houseTabs;
    },
    deepCopy(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    onScroll() {
      let scrollTop = document.documentElement.scrollTop;
      let offsetTop = this.$refs.houseTabRef.offsetTop;
      let mapScrollTop = this.$refs.mapHouseRef.offsetTop;

      if (scrollTop >= offsetTop) {
        this.needFxied = true;
        let houseTabs_ = this.deepCopy(this.houseTabs);
        houseTabs_.forEach((item, index) => {
          if (index < houseTabs_.length - 1) {
            if (
              scrollTop >= item.maxTop - 20 &&
              scrollTop < houseTabs_[index + 1].maxTop
            ) {
              item.choose = true;
            } else {
              item.choose = false;
            }
          } else {
            if (scrollTop > item.maxTop) {
              item.choose = true;
            } else {
              item.choose = false;
            }
          }
        });
        this.houseTabs = houseTabs_;
        if (scrollTop < mapScrollTop - 500) {
          this.needRightFxied = true;
        } else {
          this.needRightFxied = false;
        }
      } else {
        this.needFxied = false;
        this.needRightFxied = false;
      }
    },

    //获取房源详情
    async getHouseInfo() {
      const { data } = await realHouseServer.getRealHouseDetail(this.guid);
      // console.log(`getRealHouseDetail >`, JSON.stringify(data))
      const {
        houseNo,
        companyGuid,
        estateGuid,
        // propertyType,
        // agentGuid
      } = data;

      this.houseInfo = data;
      this.searchParamsTake.houseNo = houseNo;
      this.searchParams.estateGuid = estateGuid;
      this.searchParams.companyGuid = companyGuid;

      this.loadEstate(estateGuid);
      this.loadSecondHouse(estateGuid);
      this.loadNeighborhoodHouse(estateGuid);
    },
    // 附近房源
    async loadNeighborhoodHouse(estateGuid) {
      let params = {
        estateGuid: estateGuid,
        tradeType: 1,
        minPrice: 0,
        maxPrice: 99999,
        page: 1,
        itemsPerPage: 8,
        houseGuid: this.guid,
      };
      const { data } = await realHouseServer.getNeighborhoodHouse(params);
      this.nearHouse = data.records;
      // console.log(`getNeighborhoodHouse`, data)
    },
    //获取房源图片
    async loadHouseImags() {
      const { data } = await realHouseServer.getHouseresource(this.guid);
      this.imgs = data;
    },

    // 楼盘数据
    async loadEstate(estateGuid) {
      const { data } = await realHouseServer.getEstateById(estateGuid);
      const { entertainment, transportation, business, lat, lng } = data;
      // console.log(`getEstateById`, data)
      let tabs = JSON.parse(JSON.stringify(this.tabs));
      for (var i = 0; i < tabs.length; i++) {
        if (tabs[i].name == "小区介绍") {
          tabs[i].value = entertainment;
        }
        if (tabs[i].name == "交通出行") {
          tabs[i].value = transportation;
        }
        if (tabs[i].name == "周边配套") {
          tabs[i].value = business;
        }
      }
      this.tabs = tabs;
      this.estateInfo = data;
      this.lat = lat;
      this.lng = lng;
      if (this.tradeType == 1) {
        // this.loadNeighborhoodHouse(lng, lat)
      }
    },

    handlePrevChangeHistory(val) {
      this.changeHistoryDeal(val);
    },
    handleNextChangeHistory(val) {
      this.changeHistoryDeal(val);
    },
    handleCurrentChangeHistory(val) {
      this.changeHistoryDeal(val);
    },
    changeHistoryDeal(val) {
      this.searchParams.page = val;
      this.loadHistoryDeal();
    },
    // 周边推荐
    async loadSecondHouse(estateGuid) {
      let params = {
        page: 1,
        itemsPerPage: 8,
        tradeType: this.tradeType,
        estateGuid: estateGuid,
        houseGuid: this.guid,
      };
      const { data } = await realHouseServer.loadSecondHouse(params);
      // console.log(`loadSecondHouse >>`, data.records)
      this.recommendHouse = data.records;
    },
    // 推荐经纪人
    async loadAgentRecommend() {
      let params = {
        houseGuid: this.guid,
        limitCount: 10,
      };

      const { data } = await realHouseServer.getRealHouseRecommendById(params);
      if (data.length) {
        this.otherAgents = data;
        this.agentData = data[0];
      }
    },

    coverImgErr(event) {
      var img = event.srcElement;
      img.src = this.defaultCoverImg;
      img.onerror = null;
    },
    toMoreRecommend() {
      if (this.tradeType == 1) {
        this.$router.push("/house/1");
      } else {
        this.$router.push("/house/2");
      }
    },

    async changeCollection() {
      if (this.user) {
        if (this.isEstateAttention) {
          //取消关注
          this.isDisabled = true;
          const param = {
            houseGuid: this.houseInfo.guid,
            tradeType: 1,
          };
          const [err, { code }] = await tool.to(
            houseServer.cancelCollection(param)
          );
          this.isDisabled = false;

          if (err) {
            this.$message.error(err.message);
            this.isDisabled = false;
            return;
          }
          this.getHouseInfo();
          if (code === 0) {
            this.$message({
              type: "success",
              duration: 1500,
              message: "取消关注成功",
            });
            this.isEstateAttention = false;
          }
        } else {
          //关注
          this.isDisabled = true;
          const params = {
            houseGuid: this.houseInfo.guid,
            tradeType: 1,
          };
          const [err, { code }] = await tool.to(
            houseServer.addCollection(params)
          );
          this.isDisabled = false;

          if (err) {
            this.$message.error(err.message);
            return;
          }
          this.getHouseInfo();
          if (code === 0) {
            this.$message({
              type: "success",
              duration: 1500,
              message: "关注成功",
            });
            this.isEstateAttention = true;
          }
        }
      } else {
        this.$store.commit("updateState", {
          data: true,
          key: "showLogin",
        });
      }
    },
    // 获取关注状态
    async loadHouseCollection(guid) {
      const [err, { data }] = await tool.to(
        userServer.getCollectionStatus(guid, 2)
      );
      if (err) {
        console.log("getCollectionStatus :>>", err);
        return;
      }
      this.isEstateAttention = data;
      // console.log("getCollectionStatus :>>", data);
    },
  },
  created() {
    // this.tradeType=this.$route.params.tradeType
    this.getTradeType();
  },
  mounted() {
    let houseTabs = this.deepCopy(this.houseTabs);
    houseTabs.forEach((item) => {
      let scrollTop = document.querySelector(item.id).offsetTop;
      item.maxTop = scrollTop;
    });
    this.houseTabs = houseTabs;
    window.onscroll = tool.throttle(this.onScroll, 200);
  },
  destroyed() {
    window.onscroll = null;
  },
};
</script>

<style lang="less" scoped>
.contaienr-header {
  padding: 10px 0 17px;
}
.house-header {
  background: #f5f5f6;
  height: 100px;
  .house-title {
    max-width: 900px;
    .house-title-h {
      max-width: 900px;
      line-height: 29px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 26px;
      color: #101d37;
      font-weight: 700;
    }
    .house-title-p {
      max-width: 900px;
    }
  }
}
.house-imgs {
  padding: 28px 0;
  .house-imgs-right {
    // background: rgba(255, 255, 255, 0.39);
    border: 1px solid #e4e6f0;
    box-sizing: border-box;
    // opacity: 1;
  }
}
.width90 {
  width: 90px;
}
.width130 {
  width: 130px;
}

.house-imgs {
  padding: 28px 0;
}

.house-imgs-right {
  // width: 397px;
}
.tag-item,
.departmentName {
  padding: 0 3px;
  height: 24px;
  line-height: 24px;
  color: #889ebc;
  background-color: #f2f5f7;
  border-radius: 2px;
}
.activeTag {
  color: #fff;
  background-color: #d53c3c;
}

.tab-bottom {
  width: 50%;
  left: 50%;
  bottom: 0;
  height: 3px;
  transform: translateX(-50%);
}
.feature {
  padding: 20px 0 20px;
}
.feature-tag {
  padding: 0 9px;
  line-height: 30px;
  height: 30px;
  background-color: #f4f7f9;
  color: #849aae;
}

.near-house-item:not(:nth-of-type(4n)) {
  margin-right: 26px;
}
.near-house-item {
  width: 268px;
  margin-bottom: 53px;
  div {
    position: relative;
    left: 0;
    top: 0;
    .tag-item {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.history-title li {
  height: 50px;
  line-height: 50px;
}
.history-item {
  padding: 18px 0 26px;
}
.needFxied {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transition: all 0.3s;
}
.needRightFxied {
  position: absolute;
  width: 397px;
  top: 74px;
  right: 0;
  transition: all 0.3s;
}

.yezhu-item {
  width: 375px;
  height: 160px;
  margin-left: 12px;
  font-size: 12px;
  color: #fff;
  padding-top: 45px;
  box-sizing: border-box;
  position: relative;
  left: 0;
  top: 0;
  .yezhu-item-marsk {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.4;
    position: absolute;
    z-index: 1;
    background-color: #000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .yezhu-item-msg-tit {
    font-size: 20px;
    margin-bottom: 14px;
    z-index: 4;
  }
  .yezhu-item-msg-sub-tit {
    color: #d0d1d2;
    margin-bottom: 14px;
    z-index: 4;
  }
}
.yezhu-item-gujia {
  background: url("../../assets/imgs/yezhu-gujia.png");
}
.yezhu-item-maifang {
  background: url("../../assets/imgs/yezhu-maifang.png");
}
.yezhu-item-xiazai {
  background: url("../../assets/imgs/yezhu-xiazai.png");
  .layer-qrcode {
    display: none;
    position: absolute;
    left: 84px;
    bottom: 36px;
    width: 140px;
    padding: 28px;
    background-color: #fff;
    box-shadow: 0 0 6px 0 #ccc;
    line-height: 1;
    .icon-qrcode {
      width: 124px;
      height: 124px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    .txt {
      margin-top: 22px;
      font-weight: 700;
      color: #101d37;
    }
    .sub-txt {
      margin-top: 8px;
      font-size: 12px;
      color: #9399a5;
    }
  }
  .hoverDiv-cu {
    // cursor: alias;
  }
  .hoverDiv:hover {
    .layer-qrcode {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }
}
.house-takeLook {
  margin-top: 20px;
}
.house-takeLook {
  .takeLook-item-agentName {
    font-weight: 700;
  }
  .agent-phone {
    display: inline-block;
    font-size: 14px;
    color: #fe615a;
    margin-right: 10px;
    font-weight: 700;
  }
  .takeLook-item-des {
    color: #101d37;
    font-size: 14px;
    line-height: 25px;
    margin: 13px 0;
  }
  .takeLook-item-record {
    color: #b0b3b4;
    font-size: 12px;
    margin-bottom: 19px;
  }
}
.brand_tag {
  width: auto !important;
  padding: 0 2px;
  height: 17px;
  line-height: 17px !important;
  text-align: center;
  border-radius: 2px;
  color: #9399a5 !important;
  font-weight: 400 !important;
  font-size: 11px;
  display: inline-block;
  margin-left: 3px !important;
}
.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.departmentName {
  padding: 0 3px;
  height: 24px;
  line-height: 24px;
  color: #889ebc;
  background-color: #f2f5f7;
  border-radius: 2px;
}
.tButton {
  color: #fff;
  border-radius: 1px;
  .i {
    color: #fff;
  }
}
.phoneButton:hover {
  background: #f0fef8;
  box-sizing: border-box;
  border: #00ae66 1px solid;
  color: #00ae66;
  .i {
    color: #00ae66;
  }
}
.chatButton {
  background: #f0fef8;
  border: #00ae66 1px solid;
  color: #00ae66;
  .i {
    color: #00ae66;
  }
}
.chatButton:hover {
  box-sizing: border-box;
  background: #fff;
  color: #00ae66;
  border: #00ae66 1px solid;
  .i {
    color: #00ae66;
  }
}
.piclist {
  width: 100%;
  font-size: 0;

  li {
    display: inline-block;
    width: 283px;
    height: 212px;
    margin-top: 10px;

    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      vertical-align: middle;
    }
  }
  li:nth-child(3n-1) {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.mustLookList {
  color: #101d37;
  .mustLook-house-item-div {
    line-height: 1;
    font-size: 13px;
    margin-bottom: 24px;
    .mustLook-house-item {
      div {
        position: relative;
        left: 0;
        top: 0;
        .tag-item {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      img {
        width: 100%;
        height: 127px;
        margin-bottom: 16px;
        border-radius: 2px;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: center center;
        object-position: center center;
      }
      .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 8px;
        color: #101d37;
        line-height: 1;
      }
      .price {
        line-height: 1;
        color: #fa5741;
        font-size: 16px;
        font-family: HiraginoSansGB-W6;
        margin-top: 4px;
        margin-bottom: 3px;
      }
    }
  }
}
.img14 {
  width: 14px;
  height: 12px;
}
.mr25 {
  margin-right: 25px;
}
    .kanfangShare{
    width: 100px;
    height: 40px;
    background: #ff9000;
    color: #fff;
    text-align: center;
    line-height: 40px;
    font-weight: 700;
    font-size: 16px;
    border-radius: 4px;
    cursor:pointer;

}
.kanfangShare:hover{
    background: #ff9f00;
}

</style>

