<template>
  <div class="container">
    <div class="content">
      <div class="flex sb_center">
        <div class="flex s_center">
          <div @click='handleMenuList({
              title: "我要卖房",
              page: "/owner/2",
              choose: status == 2 ? true : false,
              status: 2,
              blank: "",
              isShow: true
          })'>
            <router-link to="/owner/2" class="flex s_center">
              <img width="42" height="42" :src="greenLogo" alt="" />
              <span style="font-size:32px" class="green pl10">{{companyConfig.firstName}}{{title}}</span>
            </router-link>
          </div>

          <ul v-if="showBanner" class="flex menu-wrap">
            <li v-for="menu in menuList" @click="handleMenuList(menu)" :key="menu.title">
              <router-link v-show="tradeType !== 3 || menu.isShow" :to="menu.page" :class="[menu.choose ? 'green':'black']" :target="menu.blank">{{menu.title}}</router-link>
            </li>
            <li v-if="tradeType === 1 && !isSourceERP" class="down-add-rent relative">
              <a href="javascript:;" class="black">下载APP</a>
              <span class="layer-qrcode" style="width:120px;padding:15px;top:30px">
                <img width="94" height="94" :src="downloadUrl" />
              </span>
            </li>
          </ul>
        </div>
        <div v-if="tradeType !== 1 && !isSourceERP">
          <div class="down-app relative">
            <a href="javascript:;" class="black size16">
              <i class="el-icon-mobile-phone " />
              <span class="pl5">下载{{companyConfig.firstName + companyConfig.lastName}}APP</span>
            </a>
            <span class="layer-qrcode">
              <span class="icon-qrcode"><img width="124" height="124" :src="downloadUrl"></span>
              <span class="txt">{{companyConfig.firstName + companyConfig.lastName}}APP</span>
              <span class="sub-txt">让家更美好</span>
            </span>
          </div>
          <!-- <div class="duty-title mt5 relative">
                        <span class="">合同示范文本</span>
                        <div class="duty-pop" style="overflow:hidden;">
                            <iframe src="https://file.ljcdn.com/rent-front-image/edfcaa1cce9c5347195d70eead4407d9.pdf?ak=HT4ASES5HLDBPFKAOEDD&amp;exp=3600&amp;ts=1648696606&amp;sign=84f749dfbe45460ef4244e79b89cf226" style="width: 100%; height: 100%" frameborder="0"></iframe>
                        </div>
                    </div> -->
        </div>

      </div>
      <div v-if="tradeType !== 3" class="flex relative search-wrap" @click.stop="">
         <a @click="$emit('update-city-select',true)" href="javascript:;" class="s-city row s_center">
            {{location.cityName}}
            <i class="el-icon-caret-bottom size10 whiteGray"></i>
          </a>
        <input type="text" :placeholder="placeholder" @focus="onFocus(true)" @keyup.enter="handleSubmit" @input="onInput" :value="estateName" />
        <a v-if="searchRecords.length && tradeType === 2" href="javascript:;" style="height:44px" class="save-btn black whiteBG flex c_center" @click="handleSaveSerch">
          {{searchRecords.length}}条已保存搜索
          <i class="pl5 el-icon-caret-bottom black size14"></i>
        </a>
        <a href="javascript:;" class="search-btn whiteBG" @click="handleSubmit">
          <i class="el-icon-search colb3 size17"></i>
        </a>
        <div class="sug-search">
          <search-tip :showSaveSerch="showSaveSerch" :estateName="estateName" :tradeType="tradeType" :showSearchTip="showSearchTip" @update-search="$emit('update-search')" />
        </div>
      </div>
      <div v-else class="release-house">
        <h1 class="tit">发布{{status === 1 ?'出租':'出售'}}房源</h1>
        <p class="sub-title">数万线下门店 · 快速全城推广 · 专业经纪人服务</p>
      </div>
    </div>
  </div>
</template>

<script>
import SearchTip from "@/components/search-tip.vue";
import { mapState } from "vuex";

export default {
  name: "houseHeader",
  props: {
    tradeType: {
      /*  tradeType 1:租房 2:售房 3:业主 4:小区 */
      type: Number,
      required: true
    },
    estateName: {
      type: String,
      default: ""
    },
    formType: {
      type: Number,
      default: 1
    }
  },
  components: {
    SearchTip
  },
  data() {
    return {
      status: 1 /* 业主状态 */,
      menuList: [],
      title: "",
      placeholder: "",
      showSaveSerch: false,
    };
  },
  computed: {
    ...mapState(["location","showSearchTip", "searchRecords","companyConfig","isSourceERP"]),
    showBanner() {
      return this.$route.path.indexOf("/house");
    },
    downloadUrl() {
      return this.companyConfig.downloadUrl
    },
    greenLogo() {
      return this.companyConfig.greenLogo
    }
  },
  watch: {
    tradeType:{
      handler(newVal, val) {
        if (newVal !== val) {
          this.onRefresh();
        }
      },
      immediate: true //第一次就监听
    }

  },
  methods: {
    handleMenuList(menu) {
      if (menu.title === "地图找房") return;
      if (menu.status) {
        this.status = menu.status;
        this.menuList = this.getMenuList();
      }
      const _menuList = this.menuList.map(cur => ({
        ...cur,
        choose: menu.title === cur.title ? true : false
      }));
      this.menuList = _menuList;
    },
    onFocus() {
      this.updateSearchTip(true);
      this.showSaveSerch = false;
    },
    updateSearchTip(isShow) {
      this.$store.commit("updateState", {
        data: isShow,
        key: "showSearchTip"
      });
    },
    onRefresh() {
      this.menuList = this.getMenuList();
      this.title = this.getTitle();
      this.placeholder = this.getPlaceholder();
    },
    getMenuList() {
      let menuList;
      switch (this.tradeType) {
        case 1:
          menuList = [
            {
              title: "首页",
              page: "/",
              choose: true,
              blank: ""
            },
            {
              title: "发布房源",
              page: "/owner/1",
              choose: false,
              tradeType: 3,
              blank: ""
            }
          ];
          break;
        case 2:
        case 4:
          menuList = [
            {
              title: "在售",
              page: "/house/2",
              choose: this.tradeType == 2 ? true : false,
              blank: ""
            },
            {
              title: "小区",
              page: "/house/4",
              tradeType: 4,
              choose: this.tradeType == 4 ? true : false,
              blank: ""
            },
            {
              title: "地图找房",
              choose: false,
              blank: "_blank",
              page: "/map"
            }
          ];
          break;
        default:
          this.status = this.formType;
          menuList = [
            {
              title: "我要卖房",
              page: "/owner/2",
              choose: this.status == 2 ? true : false,
              status: 2,
              blank: "",
              isShow: true
            },
            {
              title: "我要估价",
              page: "/",
              choose: false,
              isShow: this.status === 1 ? true : false,
              blank: ""
            },
            {
              title: "委托出租",
              page: "/owner/1",
              choose: this.status === 1 ? true : false,
              status: 1,
              isShow: true,
              blank: ""
            }
          ];
      }
      return menuList;
    },
    getTitle() {
      let title = "";
      switch (this.tradeType) {
        case 1:
          title = "租房";
          break;
        case 2:
        case 4:
          title = "二手房";
          break;
      }
      return title;
    },
    getPlaceholder() {
      let rsult = "请输入小区名开始找房";
      if (this.tradeType === 1) {
        rsult = "请输入商圈或小区名开始找房";
      }
      return rsult;
    },
    onInput(e) {
      const { value } = e.target;
      this.$emit("update-estate", value);
    },
    handleSubmit() {
      this.$emit("handle-submit");
      this.updateSearchTip(false);
    },
    handleSaveSerch() {
      this.updateSearchTip(true);
      this.showSaveSerch = true;
    }
  },

};
</script>

<style lang="less" scoped>
.container {
  background-color: #f5f5f6;
  line-height: 1;
  padding: 30px 0 26px;
  .menu-wrap {
    margin-left: 34px;
    line-height: 26px;
    font-size: 16px;
    li {
      margin-right: 30px;
      a {
        text-decoration: none;
        font-weight: 700;
      }
    }
  }
  .layer-qrcode {
    position: absolute;
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
    width: 140px;
    padding: 28px;
    background-color: #fff;
    box-shadow: 0 0 6px 0 #e0e0e1;
    line-height: 1;
    text-align: center;
    font-size: 12px;
    z-index: 1;
    display: none;
    transition: all 300ms;
    .icon-qrcode {
      width: 124px;
      height: 124px;
      margin: 0 auto;
      display: block;
    }
    .txt {
      margin-top: 22px;
      font-weight: 700;
      color: #101d37;
      display: block;
    }
    .sub-txt {
      margin-top: 8px;
      font-size: 12px;
      color: #9399a5;
      display: block;
    }
  }
  .down-add-rent,
  .down-app {
    &:hover {
      .layer-qrcode {
        display: block;
      }
    }
  }
  .search-wrap {
    height: 44px;
    margin-top: 25px;
    width: 710px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    .s-city {
      padding: 0 8px;
      border-radius: 2px;
      text-decoration: none;
      font-size: 14px;
      color: #666;
      cursor: pointer;
    }
    input {
      flex: 1;
      padding: 16px 23px 17px;
      border: none;
      outline: none;
      box-sizing: border-box;
      font-size: 13px;
      border-radius: 2px 0 2px 0;
      &::placeholder {
        color: #999999;
      }
    }

    .search-btn {
      width: 44px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      border-radius: 0 2px 2px 0;
    }
    .sug-search {
      position: absolute;
      background: #fff;
      width: 710px;
      font-size: 14px;
      z-index: 999;
    }
  }
  .release-house {
    padding-top: 46px;
    line-height: 1;
    text-align: center;
    background-color: #f5f5f6;
    .tit {
      font-weight: 700;
      color: #101d37;
      font-size: 33px;
    }
    .sub-title {
      margin-top: 15px;
      color: #878787;
      font-size: 16px;
    }
  }
  .duty-title {
    line-height: 32px;
    cursor: pointer;
    font-size: 14px;
    color: #5680a6;
    margin-left: 24px;
    text-decoration: underline;
    &:hover {
      .duty-pop {
        display: block;
      }
    }
    .duty-pop {
      position: absolute;
      right: 0;
      left: auto;
      overflow: hidden;
      height: 800px;
      text-align: center;
      z-index: 3;
      padding: 20px 20px 0;
      background: #fff;
      border-radius: 5px;
      box-sizing: border-box;
      font-size: 12px;
      line-height: 20px;
      color: #586173;
      font-weight: 400;
      white-space: normal !important;
      width: 600px;
      max-height: 600px;
      display: none;
    }
  }
}
</style>

